<template lang="pug">
    .user-verify-token.view
        section
            h1 ...
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import { VERIFY_EMAIL_TOKEN } from "@/store/types"

    export default {
        props: [
            'token'
        ],
        data () {
            return {

            }
        },
        computed: {
            ...mapGetters([
                'auth_user'
            ]),
        },
        mounted() {
            if(this.auth_user.loggedIn()){
                this.VERIFY_EMAIL_TOKEN({ token: this.token }).then(()=>{
                    this.$router.push({ name: 'index' })
                }).catch((error)=>{
                    this.$root.pushAppMessages(error.response.data)

                    this.$router.push({ name: 'user.verify' })
                })
            }else{
                // Redirect if not logged in
                this.$router.push({ name: 'user.login' })
            }
        },
        methods: {
            ...mapActions([
                VERIFY_EMAIL_TOKEN
            ]),
        }
    }
</script>

<style lang="scss" scoped>

</style>
